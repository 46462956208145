.header {
  padding: 0 !Important;
  /*box-shadow: @shadow-2;*/
  display: flex !Important;
  justify-content: space-between !Important;
  height: 72px !Important;
  z-index: 9 !Important;
  align-items: center !Important;
  /*background: #4B4B4B;dark*/
  background: #ffffff !Important;
  position: fixed !Important;
  top: 0 !Important;
  right: 0 !Important;
  width: calc(100% - 200px) !Important;
  z-index: 29 !Important;
  /*background: -webkit-linear-gradient(left,#009864, #B5C543) !Important;*/
  background: #fff !Important;
}
.header.collapsed{
  width: calc(100% - 80px) !Important;
}
.trigger{
  /*color: #fff;*/
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
@media (max-width: 700px) {
  .header.collapsed{
    width: calc(100%) !Important;
  }
}