.bg-login{
  /*background: linear-gradient(90deg,  #768a7e, #017F02) !Important;   */
  background: #fff;
  height: 100vh;
}

.form-login {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  /*height: 320px;*/
  /*padding: 36px;*/
  border-radius: 0px;
  backdrop-filter: blur(10px);
  /*border: 0px solid rgba(255,255,255,0.1);*/
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
  background: rgba(255, 255, 255, 0.13);
}



.logo-login {
  text-align: center;
  cursor: pointer;
  /*background: #088538;*/
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 30px;
  padding-bottom: 0px;
}

.logo-login img {
  /*width: 40px;*/
  height: 100px;
}

.logo-login span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  
  font-weight: 700;
  color: #367A23;
  font-size: 24px;

}
.flogin{
  padding-left: 36px;
  padding-right: 36px;
}


.footer-login{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  color:  #088538;
}
