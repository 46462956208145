@font-face {
  font-family:"Open Sans";
  /*src: url("/Roboto/Roboto-Regular.ttf");*/
}
html, body, div, span, h1, h2, h3, h4, h5{
  font-family: Open Sans;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
.konten-judul{
  text-transform: uppercase;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc; 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.content {
  padding: 10px 24px;
  min-height: calc(100% - 72px - 72px);

  margin-top: 72px;
  /*margin-left: 253px;*/
  /* margin-left: 190px; */
  
}
.ant-modal-title{
  text-transform: uppercase;
}
.titik-dua:after{
  content: ":";
  float: right;
  margin-right: 5px;
}
input[type="text"]:read-only{
  background-color: #ECEFF1 !Important;
  cursor: not-allowed !Important;
}
.container {
  height: 100vh;
  flex: 1;
  width: calc(100% - 256px);
  overflow-y: auto;
  overflow-x: hidden;
  background: #F0F2F6;
}
.scrollbar-container{
  height: auto !important;
}
.sider {
  scrollbar-width: thin;
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  box-shadow: fade(#138AFF, 10%) 0 0 28px 0;
  z-index: 10;
  height: 100vh;
  left: 0;
  /*background: url('/sider.jpg'), red;*/
  /*background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;*/
}
.ant-layout-sider-children{
  /*background-color: rgba(3,153,100, 0.7);*/
  /*background-color: rgba(3,153,100, 0.7) !Important;*/
  background: #fff;
  min-height: calc(100vh) !Important;
  height: auto; 
}
.logo{
  min-height: 72px;
  text-align: center;
  display: inline-block;

  display: flex;
  align-items: center;
  color: #367A23;
  justify-content: center;
}
.logo h1{
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
.logo img{
  width: 50px;
  height: 50px;
}
.ant-table-thead >tr>th, th{
  background: #CDF9B2 !Important;
  text-transform: uppercase;
  text-align: center !Important;

  /*white-space: nowrap;*/
  min-width: 200px !Important;
}
.ant-table-body >tr>td{
  /*width: 100%;*/
}
.ant-table-cell-fix-left{
  /*background: unset !Important;*/
}
.ant-table-tbody > .ant-table-row:nth-child(even) {
  background: #FFF;
}
.ant-table-tbody > .ant-table-row:nth-child(odd){
  /*background: #CDF9B2;*/
  background: rgba(205, 249, 178, 0.3);
}
.form-modal .ant-form-item-label{
  text-align: left !Important;
}
.form-modal .ant-form-item-label label::after{
  content: "";
}
.keterangan{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
             line-clamp: 2; 
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: blue;
}
.ant-table-thead .no-padding{
  padding: 0px !Important;
  color:  #fff !Important;
}

/*
===============
multi device screen here
================
*/
@media (max-width: 700px) {/*mobile style*/
  .header.collapsed{
    width: calc(100%);
  }
  .content {
    padding: 12px;
    margin-left: 0px !Important;
  }

  .backTop {
    right: 20px;
    bottom: 20px;
  }

  .container {
    height: 100vh;
    flex: 1;
    width: 100%;
  }
  .ant-drawer .ant-drawer-content{
    /*width: 100%;
    height: 50px;*/
  }
  .ant-drawer-top.ant-drawer-open, .ant-drawer-bottom.ant-drawer-open{
    height: 0px;
  }
  .ant-drawer-content-wrapper{
    height: 0px !Important;
  }
  .anticon-menu-unfold{
    padding-left: 20px;
  }
}